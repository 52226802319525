import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";




Vue.config.productionTip = false;

store.dispatch('auth/me').then((status) => {
    if (status) {
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount("#app")
    }
})
