<template>
<div class="container"> 
    <div class="columns has-centered">
        <!-- CARD ITEM-->
        <div class="column is-3 has-text-centered">
            <div id="item" class="card">
                <div id="logo" class="pt-4">
                    <figure>
                        <img src="../assets/sass/img/evalua/logo_evalua_activo.svg" />
                    </figure>
                </div>

                <div id="titulo" class="column">
                    <h1 class="title is-4">
                        AEDUC <font color="#2496F5">Evalúa</font>
                    </h1>
                </div>

                <div id="enunciado" class="column">
                    <h2 class="subtitle is-6">
                        Evalúa y monitorea el avance de tus estudiantes
                    </h2>
                </div>

                <div class="column">
                    <button id="boton" href="#" v-on:click="goEvalua()" class="button is-rounded" size="is-medium">
                        Ingresar
                    </button>
                </div>
            </div>
        </div>

        <!-- CARD ITEM-->
        <div  v-if="acceso.colegio.classroom" class="column is-3 has-text-centered">
            <div id="item" class="card ">
                <div id="logo " class="pt-4">
                    <figure>
                        <img src="../assets/sass/img/classroom/icono_aula_virtual.svg" />
                    </figure>
                </div>

                <div id="titulo" class="column">
                    <h1 class="title is-4">
                        AEDUC <font color="#2496F5">Aula Virtual</font>
                    </h1>
                </div>
                <div id="enunciado" class="column">
                    <h2 class="subtitle is-6">
                        Crea y monitorea las tareas de tus estudiantes
                    </h2>
                </div>

                <div class="column">
                    <button id="boton" href="#" v-on:click="goClassroom()" class="button is-rounded" size="is-medium">
                        Ingresar
                    </button>
                </div>
            </div>
        </div>
         <div  v-else class="column is-3 has-text-centered">
            <div id="item" class="card ">
                <div id="logo " class="pt-4">
                    <figure>
                        <img src="../assets/sass/img/classroom/icono_aula_virtual_gris.svg" />
                    </figure>
                </div>

                <div id="titulo" class="column">
                    <h1 class="title is-4">
                        AEDUC <font color="#2496F5">Aula Virtual</font>
                    </h1>
                </div>
                <div id="enunciado" class="column">
                    <h2 class="subtitle is-6">
                        Crea y monitorea las tareas de tus estudiantes
                    </h2>
                </div>

                <div class="column">
                    <button id="boton" href="#" v-on:click="goClassroomPago()" class="button is-rounded" size="is-medium">
                        Ingresar
                    </button>
                </div>
            </div>
        </div>
         <div  v-if="acceso.colegio.libro_digital" class="column is-3 has-text-centered">
            <div id="item" class="card ">
                <div id="logo " class="pt-4">
                    <figure>
                        <img src="../assets/sass/img/librodigital/icono_libro_digital_activo.svg" />
                    </figure>
                </div>

                <div id="titulo" class="column">
                    <h1 class="title is-4">
                        AEDUC <font color="#2496F5">Libro Digital</font>
                    </h1>
                </div>
                <div id="enunciado" class="column">
                    <h2 class="subtitle is-6">
                        Crea y monitorea tus estudiantes notas, asistencia y mucho mas.
                    </h2>
                </div>

                <div class="column">
                    <button id="boton" href="#" v-on:click="goLibroDigital()" class="button is-rounded" size="is-medium">
                        Ingresar
                    </button>
                </div>
            </div>
        </div>
         <div  v-else class="column is-3 has-text-centered">
            <div id="item" class="card ">
                <div id="logo " class="pt-4">
                    <figure>
                        <img src="../assets/sass/img/librodigital/icono_libro_digital_gris.svg" />
                    </figure>
                </div>

                <div id="titulo" class="column">
                    <h1 class="title is-4">
                        AEDUC <font color="#2496F5">Libro Digital</font>
                    </h1>
                </div>
                <div id="enunciado" class="column">
                    <h2 class="subtitle is-6">
                        Crea y monitorea tus estudiantes notas, asistencia y mucho mas.
                    </h2>
                </div>

                <div class="column">
                    <button id="boton" href="#" v-on:click="goLibroDigitalPago()" class="button is-rounded" size="is-medium">
                        Ingresar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
export default {
    name: "Home",
    computed: {
        ...mapGetters({
            
            acceso: 'auth/acceso'
        })
    },
    methods: {
        goEvalua() {
            location.href = process.env.VUE_APP_EVALUA_URL;
        },
        goClassroom() {
            location.href = process.env.VUE_APP_CLASSROOM_URL;
        },
        goClassroomPago(){
             
            this.$buefy.dialog.alert('Contacte con nosotros para obtener esta extension,<br>puedes obtener mas información en nuestra página web <br> https://www.aeduc.cl')
          
        },
        goLibroDigital() {
            location.href = process.env.VUE_APP_LIBRODIGITAL_URL;
        },
        goLibroDigitalPago(){
             
            this.$buefy.dialog.alert('Contacte con nosotros para obtener esta extension,<br>puedes obtener mas información en nuestra página web <br> https://www.aeduc.cl')
          
        }
    },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

#item {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 4px 10px #00000029;
    border-radius: 15px;
    opacity: 1;
}

#enunciado {
    text-align: center;
    font-family: Nunito;
    letter-spacing: 0px;
    color: #959595;
    
}

#boton {
    border: 1px solid #2496f5;
    border-radius: 23px;
    
}

#boton:hover {
    background: #2496f5 10%;
    color: white;
}

</style>
