<template>
<div>
    <b-navbar class="container aeduc-menu">
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                 <!--<img src="/assets/sass/img/logo_AEDUC.svg" alt="Aeduc"> -->
            </b-navbar-item>
        </template>

        <template slot="end" v-if="authenticated">
            <b-navbar-dropdown :label="user.name">
                <!-- <b-navbar-item href="/cambiar/password">
                                    Cambiar contraseña
                                </b-navbar-item> -->
                <b-navbar-item href="#" v-on:click="logout()" style="color: #363636">
                    Cerrar sesión
                </b-navbar-item>
            </b-navbar-dropdown>
        </template>
    </b-navbar>
    <section class="section">
        <router-view />
    </section>
</div>
</template>


<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Home',
        // all titles will be injected into this template
        titleTemplate: '%s | AEDUC Evalúa'
    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        })
    },

    methods: {
        ...mapActions({
            signOutAction: 'auth/signOut'
        }),

        async logout() {
            await this.signOutAction()

            location.href = process.env.VUE_APP_LOGIN_URL
        }
    }
}
</script>
